import {
  Banner,
  Box,
  ContactUsEmailLink,
  Container,
  Heading,
  Helmet,
  Layout,
  Quote,
  Row,
  Section,
  Theme,
  routesObject
} from "@life-without-barriers/react-components"
import {
  CardBannerType,
  selectBanner
} from "../../../components/child-youth-and-family/CardBanners"

import { IGatsbyImageData } from "gatsby-plugin-image"
import React from "react"
import { Site } from "@life-without-barriers/gatsby-common"
import { graphql } from "gatsby"

const { youthFull } = Theme

interface Props {
  location: { pathname: string }
  data: {
    site: Site
    bannerImage: IGatsbyImageData
  }
}

const YouthAdvocateProgramPage = ({
  location,
  data: {
    site: { siteMetadata },
    bannerImage
  }
}: Props) => (
  <div>
    <Helmet
      title={`Youth Advocate Program (YAP) | ${siteMetadata.title}`}
      description="Supporting young people to connect with their communities and lead successful lives."
      siteUrl={siteMetadata.siteUrl}
      path={location.pathname}
      image={bannerImage}
    />
    <Layout theme={youthFull}>
      <Banner
        title="Youth Advocate Program (YAP)"
        subTitle="Supporting young people leaving out-of-home care to connect with their communities and lead successful lives."
        image={bannerImage}
        breadcrumbs={[
          routesObject.home,
          routesObject.service,
          {
            to: "/services/child-youth-and-family/",
            text: "Child, youth and family",
            title: "Return to parent page"
          }
        ]}
      />
      <Container>
        <Row>
          <Box className="layout-readable">
            <Heading size={2}>Background</Heading>
            <p>
              The Youth Advocate Program (YAP) originated in the United States
              as a family support initiative for juvenile offenders returning to
              their community after spending time in correctional institutions.
              Today YAP Inc. is one of the largest non-profit agencies in the
              United States working with high-risk youth and their families in a
              strengths based, needs led advocacy model.
            </p>
            <p>
              Life Without Barriers commenced trialling YAP in Sydney in 2014,
              with a focus on assisting young people make a successful
              transition from residential care to leading successful lives in
              their communities.
            </p>
            <Heading size={2} topMargin="standard">
              Building connections
            </Heading>
            <p>
              These young people have experienced a range of difficulties around
              their families, peer group relationships, education, behaviour and
              community involvement.
            </p>
            <p>
              Relationships between the Advocates and the people they support
              are developed around the needs and aspirations of each young
              person. Advocates work intensively with individual young people
              and their families for up to 6 months, focused on rebuilding
              relationships and helping them to develop skills to participate in
              community life.
            </p>
            <p>
              Transitioning into suitable living arrangements, with or without
              ‘drop-in’ support, is among assistance that Advocates deliver.
              Practical assistance for young people and family members can also
              involve getting a driver’s licence, finding employment, exploring
              education options, learning how to budget, developing knowledge
              and confidence to join a community group or re-connecting with the
              culture.
            </p>
          </Box>
        </Row>
      </Container>
      <Section background="bg-lwb-grey-xxx-light">
        <Container>
          <Row>
            <Box className="layout-readable">
              <Heading size={2}>Outcomes</Heading>
              <p>
                YAP is being independently evaluated with the good outcomes
                including:
              </p>

              <ul className="pl4">
                <li className="mb2">
                  improved results in education, and school engagement
                </li>
                <li className="mb2">enhanced links to community</li>
                <li className="mb2">improvements in social behaviour</li>
                <li className="mb2">family restoration</li>
                <li className="mb2">
                  transition to independence or semi-independence{" "}
                </li>
              </ul>
              <p>
                21 young people commenced the program. 14 have so far graduated
                and achieved the goals they set when they entered the program.
                Five are due to graduate. The two young people who finished the
                program with partially-met goals, both reported having improved
                relations with their families.
              </p>
            </Box>
          </Row>
        </Container>
      </Section>
      <Container>
        <Row>
          <Box className="layout-readable">
            <Heading size={2}>Tailored Support</Heading>
            <p>
              Fifteen-year-old Justin* has been in foster care since he was 12.
              When his foster care placement broke down he started living in an
              Life Without Barriers Residential Service. Justin was matched with
              an Aboriginal Advocate, who worked closely with the House Manager:
            </p>
            <Quote size="large">
              &ldquo;The Advocate and I are on the same page. We talk to each
              other. This has helped me to build relationship with Justin&apos;s
              mum because the Advocate brokered the introduction. His mum
              originally saw me as a bit of a threat. Now though, his mum will
              talk with me and this benefits Justin.&rdquo;
            </Quote>
            <p>
              The House Manager and the Advocate support each other to support
              Justin:
            </p>
            <Quote size="large">
              &ldquo;If he has a bad day at school, I feel comfortable calling
              the Advocate. She talks to him, encourages him, and makes a plan
              to support him. We include Justin in open discussions. It helps
              him to feels like he belongs, without having people talking about
              him.&rdquo;
            </Quote>
            <p>
              Justin&apos;s mum is also getting the support she needs from YAP -
              mental health care, church and a women&apos;s group. Justin now
              sees his family regularly and is working towards restoration with
              his mum.
            </p>
            <p>
              Justin is now flourishing and is looking forward to a bright
              future. He is captain of his AFL footy team and because he’s a
              talented player he often gets picked to play for teams in other
              footy associations. Justin goes to a sports high school and has
              progressed from not attending at all, to being at school every
              week day.
            </p>
          </Box>
        </Row>
      </Container>
      <Section background="bg-lwb-grey-xxx-light">
        <Container>
          <Row>
            <Box className="layout-readable">
              <Heading size={2} className="color-lwb-theme-medium tc">
                Our advocates say:
              </Heading>
              <QuoteBlack>
                &ldquo;Our work is very case-by-case. For some young people or
                their families we source financial management to assist with
                budgeting, or counselling to manage the changes within their
                family environments.&rdquo;
              </QuoteBlack>
              <GreyLine />
              <QuoteBlack>
                &ldquo;We hook people up with local social groups through
                charities or church groups.&rdquo;
              </QuoteBlack>
              <GreyLine />
              <QuoteBlack>
                &ldquo;Helping young people establish a home when they leave
                care is vital. We&apos;ve helped young people understand the
                responsibilities of paying rent and utilities, but more than
                that, we&apos;ve help them create a comfortable home. We&apos;ve
                certainly seen a lot of young people become more motivated to
                take up other other opportunities once this vital piece has been
                established.&rdquo;
              </QuoteBlack>
            </Box>
          </Row>
        </Container>
      </Section>
      <Section background="bg-hex-image">
        <Container>
          <Row>
            <Box>
              <Heading size={2} className="color-lwb-theme-medium">
                Contact us
              </Heading>
              <div className="flex-l items-center-l flex-wrap-l">
                <div className="dib-ns mb2 mr3">
                  <p className="copy pt2">
                    For more information about YAP contact Suellen Lembke:
                  </p>
                </div>
                <div className="flex-l mt1-l">
                  <div className="dib ml2 mr2 mt2">
                    <ContactUsEmailLink
                      marginClasses="mt0"
                      text="suellen.lembke@lwb.org.au"
                      link="mailto:suellen.lembke@lwb.org.au"
                    />
                  </div>
                </div>
              </div>
            </Box>
          </Row>
        </Container>
      </Section>
      <Section background="bg-lwb-theme-xxx-light">
        <Container>
          <Row>
            <Box>{selectBanner(CardBannerType.ourApproach)}</Box>
          </Row>
        </Container>
      </Section>
    </Layout>
  </div>
)

interface QuoteProps {
  children: React.ReactNode
}

const QuoteBlack = ({ children }: QuoteProps) => (
  <blockquote className="ts-display-4 lh-copy fw6 fw5-ns mv4 tc">
    {children}
  </blockquote>
)

const GreyLine = () => (
  <div
    aria-hidden="true"
    className="ba color-lwb-grey-x-light mv3 mv4-ns center w-100 w-50-l"
  />
)

export const query = graphql`
  {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
    bannerImage: file(relativePath: { regex: "/kids-and-ducks.png/" }) {
      childImageSharp {
        gatsbyImageData(width: 1200, quality: 90)
      }
    }
  }
`

export default YouthAdvocateProgramPage
