import React from "react"
import {
  CardBanner,
  IconSupport,
  IconDirections
} from "@life-without-barriers/react-components"

export enum CardBannerType {
  ourApproach = "our-approach",
  wePutChildrenFirst = "we-put-children-first"
}

export const selectBanner = (bannerType: CardBannerType) => {
  switch (bannerType) {
    case CardBannerType.ourApproach:
      return <OurApproachBanner />
    case CardBannerType.wePutChildrenFirst:
      return <WePutChildrenFirstBanner />
  }
}
// eslint-disable-next-line
export const iconSelector = (
  type: string,
  color: string,
  height?: string,
  width?: string
): JSX.Element | undefined => {
  if (type === "directions") {
    return <IconDirections color={color} height={height} width={width} />
  }
  if (type === "support") {
    return <IconSupport color={color} height={height} width={width} />
  }
  return undefined
}

const OurApproachBanner = () => (
  <CardBanner
    selectIcon={iconSelector}
    title="Our approach - new directions in care"
    icon="directions"
    to={"/our-approach/child-youth-and-family/"}
    copy={`To support the best possible outcomes for children and young people in out of home care, we are implementing an evidence informed, therapeutic model of care that is grounded by the six principles of CARE: Creating Conditions for Change.`}
    mobileCopy={`To support the best possible outcomes for children and young people in out of home care, we are implementing an evidence informed, therapeutic model of care that is grounded by the six principles of CARE: Creating Conditions for Change.`}
    rooted
  />
)

const WePutChildrenFirstBanner = () => (
  <CardBanner
    selectIcon={iconSelector}
    title="We Put Children First"
    icon="support"
    to={"/our-approach/child-youth-and-family/we-put-children-first/"}
    copy={`Every day, Life Without Barriers offers support and care to children. We all have a responsibility to make sure their safety comes first – that they’re well cared for, protected from abuse and given the respect they deserve.`}
    mobileCopy={`Every day, Life Without Barriers offers support and care to children. We all have a responsibility to make sure their safety comes first.`}
    rooted
  />
)
